// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mediation-neu-gedacht-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/mediation-neu-gedacht.js" /* webpackChunkName: "component---src-pages-mediation-neu-gedacht-js" */),
  "component---src-pages-mediation-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/mediation.js" /* webpackChunkName: "component---src-pages-mediation-js" */),
  "component---src-pages-moderation-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/moderation.js" /* webpackChunkName: "component---src-pages-moderation-js" */),
  "component---src-pages-team-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-team-franziska-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/team/franziska.js" /* webpackChunkName: "component---src-pages-team-franziska-js" */),
  "component---src-pages-team-ruth-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/team/ruth.js" /* webpackChunkName: "component---src-pages-team-ruth-js" */),
  "component---src-pages-workshops-js": () => import("/home/fynn/dev/konfliktrezept/src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

